import type { Currencies, Currency } from '@patrianna/shared-patrianna-types/store/CurrenciesModule'
import { createSelector } from '@reduxjs/toolkit'

import type { ReduxState } from 'store'

const getCurrencies = (state: ReduxState) => state.currencies
export const getActiveCurrencyIdSelector = createSelector(getCurrencies, (currencies) => currencies.activeCurrencyId)
export const getAllCurrenciesSelector = createSelector(getCurrencies, (currencies) => currencies.allCurrencies)
export const getActiveModalCurrencyIdSelector = createSelector(
  getCurrencies,
  (currencies) => currencies.activeModalCurrencyId
)

export const isCurrenciesAvailableSelector = createSelector(getAllCurrenciesSelector, (currencies) =>
  Boolean(currencies?.length)
)

export const getSweepstakeCurrencySelector = createSelector(
  getAllCurrenciesSelector,
  (allCurrencies) => allCurrencies.find((i) => i.currency === 'SC') || null
)

export const getGoldCurrencySelector = createSelector(getAllCurrenciesSelector, (allCurrencies) =>
  allCurrencies.find((i) => i.currency === 'GC')
)

export const getUSDCurrencySelector = createSelector(getAllCurrenciesSelector, (allCurrencies) =>
  allCurrencies.find((i) => i.currency === 'USD')
)

export const getCurrencyInitializedSelector = createSelector(
  getActiveCurrencyIdSelector,
  (activeCurrencyId) => activeCurrencyId !== null
)

export const getBalanceByIDSelector = createSelector(
  getAllCurrenciesSelector,
  (_: ReduxState, currencyId: Currencies) => currencyId,
  (allCurrencies, currencyId) => allCurrencies.find((el) => el.currency === currencyId)
)

export const getCurrentBalanceSelector = createSelector(
  getAllCurrenciesSelector,
  getActiveCurrencyIdSelector,
  (allCurrencies, activeCurrency) => {
    return allCurrencies.find((i: Currency) => i.currency === activeCurrency)
  }
)
