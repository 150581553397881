import type { PaymentProviders } from '@patrianna/shared-patrianna-types/store/AppConfigModule'
import { createSelector } from '@reduxjs/toolkit'

import type { ReduxState } from 'store'

const getAppConfig = (state: ReduxState) => state.appConfig
const getPurchaseProviders = (state: ReduxState) => state.appConfig.paymentProviders?.purchaseProviders
export const getSoftKycAutoCompleteAddressEnabled = (state: ReduxState) => state.appConfig.softKycAutoCompleteAddress

export const getPurchaseProvidersSelector = createSelector(
  getPurchaseProviders,
  (purchaseProviders): PaymentProviders['purchaseProviders'] | null => purchaseProviders || null
)
export const getWithdrawProvidersSelector = createSelector(
  getAppConfig,
  (appConfig) => appConfig.paymentProviders?.withdrawProviders || {}
)

export const getIsSoftKycRequiredEnabledSelector = createSelector(
  getAppConfig,
  (appConfig) => appConfig.softKycRequiredEnabled
)

export const isAppInitializedSelector = createSelector(getAppConfig, (appConfig) => appConfig.init)
export const getIsPWAMessageOpenSelector = createSelector(getAppConfig, (appConfig) => appConfig.isPWAMessageOpen)
export const sweepstakeEnabledSelector = createSelector(getAppConfig, (appConfig) => appConfig.sweepstakeEnabled)
export const getLocationDataSelector = createSelector(getAppConfig, (appConfig) => appConfig.location)
export const getPasswordPolicySelector = createSelector(getAppConfig, (appConfig) => appConfig.passwordPolicy)
export const getIsStickyPlayButtonsShowedSelector = createSelector(
  getAppConfig,
  (appConfig) => appConfig.isStickyPlayButtonsShowed
)

// payment apps
export const getSpreedlyIdSelector = createSelector(
  getPurchaseProviders,
  (purchaseProviders) => purchaseProviders?.spreedly?.id || ''
)

export const getGooglePayConfigSelector = createSelector(getPurchaseProviders, (apps) => {
  if (apps?.spreedlyGooglePay?.merchantId) {
    return {
      _provider_: 'spreedly_google_pay',
      ...apps?.spreedlyGooglePay,
    }
  }

  if (apps?.fiservGooglePay?.merchantId) {
    return {
      _provider_: 'fiserv_google_pay',
      ...apps?.fiservGooglePay,
    }
  }

  return null
})

export const getApplePayConfigSelector = createSelector(getPurchaseProviders, (apps) => {
  if (apps?.spreedlyApplePay?.merchantId) {
    return {
      _provider_: 'spreedly_apple_pay',
      ...apps?.spreedlyApplePay,
    }
  }

  if (apps?.fiservApplePay?.merchantId) {
    return {
      _provider_: 'fiserv_apple_pay',
      ...apps?.fiservApplePay,
    }
  }

  return null
})

export const getFiservPaymentId = createSelector(getPurchaseProviders, (apps) => apps?.fiserv?.id || '')

// TODO will mve this selector to pkg
export const getTrustlyConfigSelector = createSelector(getPurchaseProvidersSelector, (apps) => apps?.trustly)
