import { createSelector } from '@reduxjs/toolkit'

import type { ReduxState } from 'store'
import { sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'

const getGcGeneratorRoot = (state: ReduxState) => state.gcGenerator

export const getGcGeneratorSettingsSelector = createSelector(getGcGeneratorRoot, (gcGenerator) => gcGenerator.settings)

export const getGcGeneratorInterval = createSelector(getGcGeneratorSettingsSelector, (settings) => settings.interval)

export const getGcGeneratorAmount = createSelector(getGcGeneratorSettingsSelector, (settings) => settings.amount)

export const getGcGeneratorAccepted = createSelector(getGcGeneratorRoot, (gcGenerator) => gcGenerator.accepted)

export const getGcGeneratorAccepting = createSelector(getGcGeneratorRoot, (gcGenerator) => gcGenerator.accepting)

export const getGcGeneratorIntervalDate = createSelector(
  getGcGeneratorInterval,
  getGcGeneratorAccepted,
  (interval, accepted): string | null =>
    interval && accepted ? new Date(Number(interval) + Number(accepted)).toString() : null
)

export const getGcGeneratorEnabledForUser = createSelector(getGcGeneratorRoot, (gcGenerator) => gcGenerator.enabled)

export const getGcGeneratorEnabled = createSelector(
  getGcGeneratorEnabledForUser,
  sweepstakeEnabledSelector,
  (enabled, sweepstakeEnabled) => enabled && !sweepstakeEnabled && false
)

export const getGcGeneratorLoading = createSelector(getGcGeneratorRoot, (gcGenerator) => gcGenerator.loading)
