import { createAsyncThunk } from '@reduxjs/toolkit'
import { useDispatch, useSelector, useStore } from 'react-redux'

import type { ServiceDependenciesOptions } from 'services'
import type { ReduxState, AppDispatch, AppStore } from 'store'

type PayloadCreator<Returned, ThunkArg, ThunkApiConfig> = Parameters<
  typeof createAsyncThunk<Returned, ThunkArg, ThunkApiConfig>
>[1]
type OptionsCreator<Returned, ThunkArg, ThunkApiConfig> = Parameters<
  typeof createAsyncThunk<Returned, ThunkArg, ThunkApiConfig>
>[2]

export const createAppAsyncThunk = <
  Returned,
  ThunkArg = unknown,
  ThunkApiConfig extends { extra: ServiceDependenciesOptions } = { extra: ServiceDependenciesOptions },
>(
  typePrefix: string,
  payloadCreator: PayloadCreator<Returned, ThunkArg, ThunkApiConfig>,
  options?: OptionsCreator<Returned, ThunkArg, ThunkApiConfig>
) => createAsyncThunk<Returned, ThunkArg, ThunkApiConfig>(typePrefix, payloadCreator, options)

export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<ReduxState>()
export const useAppStore = useStore.withTypes<AppStore>()
